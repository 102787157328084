body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html { scrollbar-width: none; } /* Firefox */
body { -ms-overflow-style: none; } /* IE and Edge */
body::-webkit-scrollbar, body::-webkit-scrollbar-button { display: none; } /* Chrome */

h1, h2 {
  margin: 0px;
}

p {
  margin: 0px;
  text-align: left;
}

.main-container {
  z-index: 10;
  position: absolute;
  color: white;
  /* background: linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0)); */
  /* backdrop-filter: blur(5px); */
  /* -webkit-backdrop-filter: blur(5px); */
  bottom: -200px;
  top: 0px;
  left: 0px;
  right: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.grid {
  /* background-color: red; */
  margin-top: 50px;
  width: 1200px;
  height: 1000px;
  display: flex;
  flex-direction: column;
}

.row1, .row2, .row3, .row4 {
  display: flex;
}

.aboutme {
  flex: 1.5;
  height: 250px;
  background-color: blue;
  margin: 8px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0)); 
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  box-shadow: 2px 2px 20px rgba(121, 121, 121, 0.1);
  border-width: 1px;
  border-style: solid;
  border-color: rgb(46, 46, 46);
  transition: all 0.2s;
}

.aboutme:hover {
  transform: scale(1.05);
}

.aboutme p {
  padding: 15px;
  margin: 0px 20px;
}

.name {
  display: flex;
  justify-content: center;
  align-items: center;
}

.pfp {
  height: 100px;
  border-radius: 75px;
  margin-right: 30px;
}

.map-resume-email {
  display: flex;
}

.map {
  flex: 1;
  height: 250px;
  width: 400px;
  background-color: blue;
  margin: 8px;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 2px 2px 20px rgba(121, 121, 121, 0.1);
  position: relative;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(46, 46, 46);
  transition: all 0.2s;
}

.map:hover {
  transform: scale(1.05);
}

.map img {
  position: relative;
  width: 400px;
  right: 20px;
  bottom: 150px;
}

.resume-and-email {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.resume, .email{
  flex: 1;
  height: 250px;
  background-color: blue;
  margin: 8px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0)); 
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  box-shadow: 2px 2px 20px rgba(121, 121, 121, 0.1);
  border-width: 1px;
  border-style: solid;
  border-color: rgb(46, 46, 46);
  transition: all 0.2s;
}

.resume:hover, .email:hover {
  transform: scale(1.05);
}

.resume img {
  margin-top: 5px;
  margin-left: 20px;
}

.resume-image {
  width: 60px;
  transition: all 0.2s;
}

.resume-image:hover {
  opacity: 0.7;
}

.scroller-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 250px;
  margin: 8px;
  border-radius: 20px;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  position: relative;
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0)); 
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  box-shadow: 2px 2px 20px rgba(121, 121, 121, 0.1);
  border-width: 1px;
  border-style: solid;
  border-color: rgb(46, 46, 46);
  transition: all 0.2s;
}

.scroller-container:hover {
  transform: scaley(1.05);
  z-index: 2;
}


.github {
  width: 150px;
  height: 118px;
  background-color: blue;
  margin: 8px;
  margin-bottom: 14px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0)); 
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  box-shadow: 2px 2px 20px rgba(121, 121, 121, 0.1);
  border-width: 1px;
  border-style: solid;
  border-color: rgb(46, 46, 46);
}

.github, .social {
  transition: all 0.2s;
}

.github:hover, .social:hover {
  transform: scale(1.1);
}

.github img, .social img {
  height: 60px;
  transition: all 0.2s;
}

.github img:hover, .social img:hover {
  opacity: 0.7;
}

.social {
  width: 150px;
  height: 118px;
  background-color: blue;
  margin: 8px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0)); 
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  box-shadow: 2px 2px 20px rgba(121, 121, 121, 0.1);
  border-width: 1px;
  border-style: solid;
  border-color: rgb(46, 46, 46);
}

.column1, .column2 {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.project1, .project2, .project3, .project4 {
  height: 390px;
  margin: 8px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  padding: 15px;
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0)); 
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  box-shadow: 2px 2px 20px rgba(121, 121, 121, 0.1);
  border-width: 1px;
  border-style: solid;
  border-color: rgb(46, 46, 46);
  transition: all 0.2s;
}

.project1:hover, .project2:hover, .project3:hover {
  transform: scale(1.05);
}

.project1 {
  height: 450px;
}

.project2 img, .project3 img, .project4 img {
  width: 100%;
  height: 300px;
  object-fit: none;
  object-position: -120px -60px;
  border-radius: 10px;
}

.project1 img {
  width: 100%;
  border-radius: 10px;
}

.project2 img {
  width: 100%;
  height: 300px;
  object-fit: none;
  object-position: -120px -40px;
}

.tags {
  display: flex;
  margin-bottom: 4px;
}

.project3 .tags, .project3 p {
  margin-top: 10px;
}


.tag {
  background-color: rgb(80, 80, 80);
  border-radius: 15px;
  padding: 1px 10px;
  padding-bottom: 3px;
  margin-right: 10px;
}

.project1 p, .project2 p {
  margin-top: 10px;
}

.project1 h2, .project2 h2 {
  margin-bottom: 10px;
}

.project4 {
  visibility: hidden;
}

.scroller-container h2 {
  margin-top: 20px;
}

.scroller-container:before, .scroller-container:after {
  position: absolute;
  top: 0;
  width: 250px;
  height: 100%;
  content: "";
  z-index: 2;
}

.scroller-container:before {
  left: 0;
  background: linear-gradient(to left, rgba(255, 255, 255, 0), #18191A);
}

.scroller-container:after {
  right: 0;
  background: linear-gradient(to right, rgba(255, 255, 255, 0), #18191A);
}

.logos {
  overflow: hidden;
  white-space: nowrap;
  margin-left: 1000px;
}

.logos-slide img {
  height: 75px;
  margin: 0 25px;
}

.logos-slide {
  display: inline-block;
  animation: 15s slide infinite linear;
}

@keyframes slide {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}

@media (max-width: 1200px) {

  .pfp {
    margin-top: 10px;
  }

  .grid {
    width: 1000px;
  }
  .project1 {
    height: 400px;
  }
  .project2 {
    height: 408px;
  }
  .project3 {
    margin-top: -1px;
  }
}

@media (max-width: 1000px) {

  .grid {
    width: 800px;
  }
  .map {
    width: 300px;
  }
  .pfp {
    height: 80px;
    margin-left: 30px;
    margin-top: 5px;
  }
  .project1 {
    height: 375px;
  }
  .project3 {
    margin-top: -23px;
  }
}

@media (max-width: 800px) {

  .aboutme {
    margin-top: 150px;
  }

  .project1 {
    height: 320px;
  }
  .grid {
    width: 400px;
  }

  .row1 {
    flex-direction: column;
  }

  h2 {
    font-size: 16px;
  }
  p {
    font-size: 14px;
  }

  .tag {
    font-size: 14px;
  }

  .row3, .row4 {
    flex-direction: column;
  }

  .pfp {
    height: 100px;
    margin-top: 15px;
  }

  .scroller-container:before, .scroller-container:after {
    position: absolute;
    top: 0;
    width: 0px;
    height: 100%;
    content: "";
    z-index: 2;
  }

  .map img {
  position: relative;
  width: 400px;
  right: 100px;
  bottom: 150px;
}
}

